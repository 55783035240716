:root {
  --font-family-base: 'Nexa', sans-serif;
  --font-family-condensed: 'Americane-condensed-medium', sans-serif;
  --font-family-italic: 'IBMPlex-serif-italic', sans-serif;

  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-700: 700;

  --line-height-heading-condensed: 0.75;
  --line-height-normal: 1;
  --line-height-subheading: 1.2;
  --line-height-paragraph: 1.3;
  --line-height-text: 1.5;

  --letter-spacing-text: -0.25px;
  --letter-spacing-heading: -2px;
  --letter-spacing-subheading: -1px;

  /* Sizes */
  --font-size-unit: 1px;
  --font-size-viewport-min: 480;
  --font-size-viewport-max: 1920;
  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc((var(--font-size-growth) * 100vw) - (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit)));

  --letter-spacing-heading: clamp(-2px, calc(-1px - 1 * var(--m)), -1px);
  --letter-spacing-heading-xl: clamp(-4px, calc(-2px - 2 * var(--m)), -2px);

  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-30: 30px;
  --font-size-40: 40px;
  --font-size-48: 48px;

  --font-size-16-18: clamp(16px, calc(16px + 2 * var(--m)), 18px);
  --font-size-18-20: clamp(18px, calc(18px + 2 * var(--m)), 20px);
  --font-size-20-25: clamp(20px, calc(20px + 5 * var(--m)), 25px);
  --font-size-24-35: clamp(24px, calc(24px + 11 * var(--m)), 35px);
  --font-size-32-48: clamp(32px, calc(32px + 16 * var(--m)), 48px);
  --font-size-30-40: clamp(30px, calc(30px + 10 * var(--m)), 40px);
  --font-size-34-42: clamp(34px, calc(34px + 8 * var(--m)), 42px);
  --font-size-40-48: clamp(40px, calc(40px + 8 * var(--m)), 48px);
  --font-size-36-55: clamp(36px, calc(36px + 19 * var(--m)), 55px);
  --font-size-48-55: clamp(48px, calc(48px + 7 * var(--m)), 55px);
  --font-size-48-70: clamp(48px, calc(48px + 22 * var(--m)), 70px);
  --font-size-68-100: clamp(68px, calc(68px + 32 * var(--m)), 100px);
  --font-size-50-100: clamp(50px, calc(50px + 50 * var(--m)), 100px);
  --font-size-50-150: clamp(50px, calc(50px + 100 * var(--m)), 150px);

  --font-size-60-120: clamp(60px, calc(60px + 60 * var(--m)), 120px);
  --font-size-60-130: clamp(60px, calc(60px + 70 * var(--m)), 130px);
  --font-size-60-150: clamp(60px, calc(60px + 90 * var(--m)), 150px);

  --font-size-80-150: clamp(80px, calc(80px + 70 * var(--m)), 150px);
  --font-size-80-240: clamp(80px, calc(80px + 160 * var(--m)), 240px);
  --font-size-150-320: clamp(150px, calc(150px + 170 * var(--m)), 320px);
}
