:root {
  --color-white: #fff;
  --color-white--10: rgba(255, 255, 255, 0.1);
  --color-white--30: rgba(255, 255, 255, 0.3);

  --color-black: #000;
  --color-black--30: rgba(0, 0, 0, 0.3);
  --color-black--20: rgba(0, 0, 0, 0.2);

  --color-grey-200: #e0e0e0;
  --color-grey-600: #666;
  --color-grey-900: #202122;

  --color-red-500: #e32b31;
  --color-red-700: #c6242a;

  --color-peach-200: #fce8e1;
  --color-peach-500: #fad2c4;

  --color-green-500: #00cd66;
}
