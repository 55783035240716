:root {
  --container-max-width-sm: 480px;
  --container-max-width-md: 768px;
  --container-max-width-lg: 1024px;
  --container-max-width-xl: 1920px;
  --container-max-width-xxl: 2160px;

  --container-padding-size: min(5vw, calc(2.5vw + 24px));
  --container-padding: 0 var(--container-padding-size);
}
